
<template>
    <div class="pager">
        <div class="nav">
            <van-nav-bar :title="$t('purchase.nav_title')" left-arrow @click-left="$router.go(-1)" />
        </div>

        <div class="box">

            <div class="top">
                <img :src="info.icon">
                <div style="flex: 1;align-items: flex-start;">
                    <span>{{ $t('purchase.top.title') }}</span>
                    <span>{{ info.name }}</span>
                </div>
                <div>
                    <span>{{ $t('purchase.top.income') }}</span>
                    <span>{{ info.day_rate }}%</span>
                </div>
            </div>
            <div class="financialCycle">
                <div class="cycle_top">
                    <div>
                        <span>{{ info.days }}{{ $t('financial.list.sufix') }}</span>
                        <span>{{ $t('purchase.financialCycle') }}</span>
                    </div>
                    <div>
                        <span>{{ info.min }}~{{ info.max }}</span>
                        <span>{{ $t('purchase.limitation') }}</span>
                    </div>
                </div>
                <div style="height: 41px;" />
                <div class="cycle_bottom">
                    <div>
                        <span>{{ $t('purchase.payout_time') }}</span>
                        <span>{{ $t('purchase.MaturitySettlement') }}</span>
                    </div>
                    <div>
                        <span>{{ $t('purchase.EscrowFunds') }}</span>
                        <span>{{ $t('purchase.Expirationback') }}</span>
                    </div>
                    <div>
                        <span>{{ $t('purchase.redemption') }}</span>
                        <span>{{ info.wy_rate }}%</span>
                    </div>
                </div>
            </div>
            <div class="income">
                <div>
                    <span>{{ $t('purchase.estimate') }}</span>
                    <span>{{ estimate }}</span>
                </div>
                <div>
                    <span>{{ $t('purchase.availableAssets') }}</span>
                    <span>{{ available }}</span>
                </div>
            </div>
            <div class="investment">
                <div>
                    <span>{{ $t('purchase.investment') }}</span>
                </div>
                <div>
                    <input type="text" :placeholder="$t('purchase.placeholder')" v-model="value"
                        oninput="this.value=this.value.replace(/[^\d.]/g,'').replace(/(\.\d+)\.+/g, '$1')" />
                    <div>
                        <span>{{ $t('fuhao') }}</span>
                        <span @click="value = available">{{ $t('All') }}</span>
                    </div>
                </div>
            </div>
            <div class="tips">
                <span>{{ $t('purchase.tips.title') }}</span>
                <span>{{ $t('purchase.tips.desc') }}</span>
            </div>
            <van-button type="primary" style="margin-top: 95px;" @click="onSubscribe">{{ $t('purchase.subscribe')
            }}</van-button>
        </div>


    </div>
</template>

<script>
export default {
    data() {
        return {
            id: "",
            info: {},
            estimate: 0,
            available: "0",
            value: ''
        }
    },
    watch: {
        value: {
            handler(val) {
                const value = val * (this.info.day_rate / 100) * this.info.days

                this.estimate = value == 0 ? value : value.toFixed(2)
            }

        }
    },
    created() {
        this.id = this.$route.params.id
        this.onLoadData()
    },
    methods: {
        onLoadData() {
            this.$http.get("/home/index/wmpInfo?id=" + this.id).then(res => {
                if (res.data.code == 200) {
                    this.info = res.data.data;
                }
            })
            this.$http.get("/home/user/index").then(res => {
                if (res.data.code == 200) {
                    const { total = '0' } = res.data.data.balance;
                    this.available = total;
                }
            });
        },
        onSubscribe() {
            if (this.value == '') {
                return this.$toast(this.$t('purchase.placeholder'))
            }
            // if (this.value > this.available) {
            //     return this.$toast(this.$t('余额不足'))
            // }
            if (parseFloat(this.value) < this.info.min || parseFloat(this.value) > this.info.max) {
                return this.$toast(this.$t('purchase.limitationPlaceholder'))
            }
            this.$http.post('/home/home/wmpOrder', {
                product_id: this.id,
                total: this.value
            }).then(res => {
                if (res.data.code == 200) {
                    this.$toast(this.$t("common.actionok"));
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1000)


                } else {
                    this.$toast(this.$t(res.data.msg));
                }
            }).catch(err => {
                this.$toast(this.$t("common.actionok"));
            })
        }
    }


}

</script>

<style scoped lang="less">
.pager {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #E3E6EA;
    overflow-y: scroll;
}

.nav {
    position: fixed;
    width: 100vw;
    font-size: 1.12rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
    z-index: 999;
}

.box {
    padding: 46px 16px 0px 16px;
    width: 100vw;

    .top {
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 39px;
            height: 39px;
            margin-right: 11px;
        }

        div {
            display: flex;
            flex-direction: column;
            color: #333;

            span:first-child {
                font-size: 13px;
                font-weight: 400;
            }

            span:last-child {

                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .financialCycle {
        margin-top: 26px;
        position: relative;

        .cycle_top {
            top: 0px;
            left: 14px;
            width: calc(100% - 28px);
            position: absolute;
            background-color: #fff;
            padding: 14px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            div {
                display: flex;
                flex-direction: column;
                color: #333;

                span:first-child {
                    font-size: 16px;
                    font-weight: 500;
                }

                span:last-child {
                    margin-top: 6px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

        }

        .cycle_bottom {
            padding: 54px 0 19px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            background: rgba(255, 255, 255, 0.55);

            div {
                display: flex;
                flex-direction: column;

                span:first-child {
                    color: #777;
                    font-size: 14px;
                    font-weight: 400;
                }

                span:last-child {
                    color: #363636;
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }

    .income {
        margin-top: 10px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        background: rgba(255, 255, 255, 0.55);


        div {
            display: flex;
            flex-direction: column;

            span:first-child {
                color: #777;
                font-size: 14px;
                font-weight: 400;
            }

            span:last-child {
                color: #363636;
                margin-top: 15px;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .investment {
        margin-top: 10px;
        padding: 16px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.55);

        div:first-child {
            color: #333;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 9px;
        }

        div:last-child {
            width: 100%;
            height: 38px;
            background: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 8px;

            input {
                flex: 2;
                border: none;
                color: #333;
                font-size: 15px;
                font-weight: 400;
            }

            div {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                span:first-child {
                    color: #777;
                    font-size: 12px;
                    font-weight: 400;
                }

                span:last-child {
                    color: #363636;
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }

    .tips {
        margin-top: 10px;
        padding: 16px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.55);

        span:first-child {
            color: #333;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 9px;
        }

        span:last-child {
            color: #333;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
        }
    }

    /deep/.van-button--primary {
        width: 100%;
        border-radius: 5px;
        background: #f0b82d;
        border: none;
    }
}
</style>